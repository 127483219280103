import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import store from './redux/store';
import Views from './views';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
          <NotificationContainer />
        </Router>
      </Provider>
    </div>
  );
}

export default App;
