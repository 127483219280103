import { env } from './EnvironmentConfig';
import { SIDE_NAV_DARK, NAV_TYPE_SIDE } from '../constants/ThemeConstant';

export const APP_NAME = 'Agfinmart Campgian';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_DOC_UPLOAD_URL = env.API_DOC_UPLOAD_ENDPOINT_URL;
export const USER_GWURL = env.USER_GWURL;
export const ADMIN_GWURL = env.ADMIN_GWURL;
export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_DARK,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '#ffffff',
  mobileNav: false
};
