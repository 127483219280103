import { combineReducers } from 'redux';
import { DEFAULT_STATE, DEFAULT_STATE_FF_EF } from '../../utils/Constants';
import Auth from './Auth';
import Theme from './Theme';

const createReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}_PENDING`:
      case `${reducerName}_FULFILLED`:
      case `${reducerName}_REJECTED`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

const createStaticReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  login: createReducer('LOGIN', DEFAULT_STATE_FF_EF),
  cinAdd: createReducer('CIN_ADD', DEFAULT_STATE_FF_EF),
  currentTab: createStaticReducer('CURRENT_TAB_SECTION', {
    showTab: false,
    currentTab: '1'
  }),
  applicationStatus: createReducer('APPLICATION_STATUS', DEFAULT_STATE_FF_EF),
  applicationAdd: createReducer('APPLICATION_ADD', DEFAULT_STATE_FF_EF),
  applicationEdit: createReducer('APPLICATION_EDIT', DEFAULT_STATE_FF_EF),
  applicationProcess: createReducer('APPLICATION_PROCESS', DEFAULT_STATE_FF_EF),
  bankDetailsVerification: createReducer(
    'BANK_DETAILS_VERIFY',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListDraft: createReducer(
    'ADMIN_ALL_LOAN_LIST_DRAFT',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationList: createReducer('ADMIN_ALL_LOAN_LIST', DEFAULT_STATE_FF_EF),
  applicationListData: createReducer(
    'APPLICATION_LOAN_LIST',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListDocuments: createReducer(
    'ADMIN_ALL_LOAN_LIST_DOCUMENTS',
    DEFAULT_STATE_FF_EF
  ),

  allApplicationListAccepted: createReducer(
    'ADMIN_ALL_LOAN_LIST_ACCEPTED',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListRejected: createReducer(
    'ADMIN_ALL_LOAN_LIST_REJECTED',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListOnHold: createReducer(
    'ADMIN_ALL_LOAN_LIST_ONHOLD',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListIndicativeApproval: createReducer(
    'ADMIN_ALL_LOAN_LIST_INDICATIVE',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListdocumentRecieved: createReducer(
    'ADMIN_ALL_DOCUMENTS_RECIEVED',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListdocumentReSubmission: createReducer(
    'ADMIN_ALL_DOCUMENTS_RESUBMISSION',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListLSAGenerated: createReducer(
    'ADMIN_ALL_LSA_GENERATED',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListLetterRecieved: createReducer(
    'ADMIN_ALL_REQUEST_LETTER_RECIEVED',
    DEFAULT_STATE_FF_EF
  ),
  allApplicationListdisbursementDone: createReducer(
    'ADMIN_ALL_DISBURSEMENT_DONE',
    DEFAULT_STATE_FF_EF
  ),
  applicationById: createReducer(
    'ADMIN_APPLICATION_BY_ID',
    DEFAULT_STATE_FF_EF
  ),
  verifyApplicationByTabs: createReducer(
    'ADMIN_APPLICATION_VERIFICATION_BY_TAB',
    DEFAULT_STATE_FF_EF
  )
});

export default reducers;
