import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
export const StatusViews = ({ match }) => {

	return (
		<Suspense fallback={<Loading cover="page" />}>
			<Switch>
				<Route
					path={`${match.url}`}
					component={lazy(() => import(`./ApproveReject`))}
				/>
			</Switch>
		</Suspense>
	);
};

export default StatusViews;
