import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${match.url}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`admin/${match.url}/login`}
          component={lazy(() => import(`./authentication/adminLogin`))}
        />
        <Redirect from={`${match.url}`} to={`${match.url}/login`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
