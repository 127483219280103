import React from 'react';
import { Layout, Grid } from 'antd';

import { Switch, Route } from 'react-router-dom';
import AuthViews from 'views/auth-views';
import HeaderNav from '../../components/layout-components/HeaderNav';
const { Content } = Layout;

export const AuthLayout = () => {
  return (
    // <Layout>
    /* <HeaderNav isMobile={false} isFrontPage={true} /> */

    <div className="auth-container">
      {/* <Content> */}
      <Switch>
        <Route path="" component={AuthViews} />
      </Switch>
      {/* </Content> */}
    </div>
    // </Layout>
  );
};

export default AuthLayout;
