import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Authorization from 'utils/Authorization';
const userRoles = Authorization.getAuthRoles()?.roles || '';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      {userRoles &&
      (userRoles.includes('admin') || userRoles.includes('cad')) ? (
        // return(
        <Switch>
          <Route
            path={`${match.url}/admin/home`}
            component={lazy(() => import(`./AdminView/HomePage`))}
          />
          <Route
            path={`${match.url}/admin/application/:id`}
            component={lazy(() => import(`./AdminView/LoanView`))}
          />
          <Route
            path={`${match.url}/dashboard`}
            component={lazy(() => import(`./AdminView/DashboardViews`))}
          />
          <Route
            path={`${match.url}/promoting-institutions-dashboard`}
            component={lazy(() =>
              import(`./AdminView/DashboardViews/PromotingInstitutions`)
            )}
          />
          <Route
            path={`${match.url}/crops-grown-dashboard`}
            component={lazy(() =>
              import(`./AdminView/DashboardViews/CropsGrown`)
            )}
          />
          <Route
            path={`${match.url}/business-type-dashboard`}
            component={lazy(() =>
              import(`./AdminView/DashboardViews/BusinessType`)
            )}
          />
          <Route
            path={`${match.url}/application-TAT-dashboard`}
            component={lazy(() =>
              import(`./AdminView/DashboardViews/ApplicationTAT`)
            )}
          />
          <Route
            path={`${match.url}/application-dashboard`}
            component={lazy(() =>
              import(`./AdminView/DashboardViews/FunnelChartDetails`)
            )}
          />
          <Route
            path={`${match.url}/fpo-dashboard`}
            component={lazy(() => import(`./AdminView/DashboardViews/FPO`))}
          />
          <Route
            path={`${match.url}/faq`}
            component={lazy(() => import(`./FAQ`))}
          />
          <Redirect from={match.url} to={`${match.url}/admin/home`} />
        </Switch>
      ) : (
        <Switch>
          <Route
            path={`${match.url}/applications`}
            component={lazy(() => import(`./ApplicationList`))}
          />
          <Route
            path={`${match.url}/home`}
            component={lazy(() => import(`./HomePage`))}
          />
          <Route
            path={`${match.url}/form`}
            exact
            component={lazy(() => import(`./FormPage`))}
          />
          <Route
            path={`${match.url}/form-status`}
            exact
            component={lazy(() => import(`./FormStatus`))}
          />
          <Route
            path={`${match.url}/form-status/:appId`}
            exact
            component={lazy(() => import(`./FormStatus`))}
          />
          <Route
            path={`${match.url}/form-add-detail`}
            component={lazy(() => import(`./FormStatusGrading`))}
          />
          {/* <Route
            path={`${match.url}/form-add-kyc`}
            component={lazy(() => import(`./KYCUpload`))}
          /> */}
          <Route
            path={`${match.url}/application-status`}
            component={lazy(() => import(`./ApplicationStatus`))}
          />
          <Route
            path={`${match.url}/faq`}
            component={lazy(() => import(`./FAQ`))}
          />
          <Redirect from={match.url} to={`${match.url}/home`} />
        </Switch>
      )}
    </Suspense>
  );
};

export default AppViews;
