import React from 'react';
import { Switch, Route } from 'react-router-dom';
import StatusViews from 'views/status-views';
export const StatusLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={StatusViews} />
      </Switch>
    </div>
  );
};

export default StatusLayout;
