import moment from 'moment';

export const downloadCsvFunction = (data, fileName) => {
  var a = window.document.createElement('a');
  var blob = new Blob([data], {
    type: 'text/csv;charset=utf-8;'
  });
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(
      blob,
      `${fileName} (${moment().format('DD/MM/YYYY hh:mm')}).csv`
    );
  } else {
    a.href = window.URL.createObjectURL(blob);
    // supply your own fileName here...
    a.download = `${fileName} (${moment().format('DD/MM/YYYY hh:mm')}).csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const viewFile = (fileUrl, handleFileView, isWhiteText = false) => {
  if (fileUrl) {
    return fileUrl?.includes?.('https') ? (
      <a href={fileUrl} target="_blank" rel="noreferrer">
        View
      </a>
    ) : (
      <p
        className={`mb-0 ${isWhiteText ? 'text-white' : ''}`}
        onClick={handleFileView}
      >
        View
      </p>
    );
  }
};

export const defaultValidator = (data) => {
  return data ? data : '';
};
