import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderNav from '../../components/layout-components/HeaderNav';
import PageHeader from '../../components/layout-components/PageHeader';
import AppViews from 'views/app-views';
import { NAV_TYPE_TOP } from '../../constants/ThemeConstant';
import utils from 'utils';

const { Content } = Layout;

export const AppLayout = ({ navType, location }) => {
  const currentRouteInfo = utils.getRouteInfo(location.pathname);
  const isNavTop = navType === NAV_TYPE_TOP;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Layout>
      {!JSON.parse(localStorage.getItem('isMobile')) && (
        <HeaderNav isMobile={isMobile} isFrontPage={false} />
      )}

      <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
        <PageHeader
          display={currentRouteInfo?.breadcrumb}
          title={currentRouteInfo?.title}
        />
        <Content>
          <Switch>
            <Route path="" component={AppViews} />
          </Switch>
        </Content>
      </div>
      {/* <Footer /> */}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navType, locale } = theme;
  return { navType, locale };
};

export default withRouter(connect(mapStateToProps)(AppLayout));
