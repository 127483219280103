import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Layout, Modal, Divider } from 'antd';
import Logo from './Logo';
import utils from 'utils';
import Authorization from 'utils/Authorization';
import {
  CloseCircleFilled,
  QuestionCircleFilled,
  UserOutlined
} from '@ant-design/icons';
import { API_BASE_URL, USER_GWURL } from 'configs/AppConfig';
import axios from 'axios';
import { downloadCsvFunction } from 'services/helperFunctions';
import { ls } from 'utils/LocalStorage';
import { Popover } from 'antd';
import { AddFeedback } from 'components/Common/Feedback/AddFeedback';
import { useHistory } from 'react-router-dom';
const userRoles = Authorization.getAuthRoles()?.roles || '';

const accessToken = Authorization.getAccessToken();

const { Header } = Layout;

export const HeaderNav = (props) => {
  const { headerNavColor, isFrontPage = false } = props;
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const mode = utils.getColorContrast(headerNavColor);
  const mobileNo = Authorization.getAuthRoles();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleLSAMIS = () => {
    axios({
      method: 'get',
      url: `${API_BASE_URL}/admin/downloadLSA`,
      headers: { Authorization: accessToken }
    }).then(({ data }) => {
      downloadCsvFunction(data, 'LSA-MIS');
    });
  };

  const handleLogout = () => {
    // ls.removeItem('audetails');
    ls.clear();
    if (userRoles.includes('admin') || userRoles.includes('cad')) {
      history.push('/admin/auth/login');
      Authorization.logout();
    } else {
      window.open(`${USER_GWURL}/login`, '_self');
    }
  };

  const handleGatewayHome = () => {
    window.open(`${USER_GWURL}/fpo/home`, '_self');
  };

  const handleProfile = () => {
    window.open(`${USER_GWURL}/fpo/profile`, '_self');
  };

  const content = (
    <React.Fragment>
      {!userRoles.includes('admin') && !userRoles.includes('cad') ? (
        <React.Fragment>
          <p
            className="cursor-pointer font-weight-bold mb-1"
            onClick={() => handleProfile()}
          >
            My Profile
          </p>
          <p
            className="cursor-pointer font-weight-bold mb-1"
            onClick={showModal}
          >
            Feedback
          </p>
        </React.Fragment>
      ) : null}
      <div>
        <div
          style={{
            color: '#72849a',
            fontSize: 14
          }}
          className=" d-md-none d-lg-none align-items-center font-weight-bold mb-1 cursor-pointer"
          onClick={() => handleGatewayHome()}
        >
          FPO Gateway
        </div>
        <a
          href="/app/faq"
          style={{ color: '#72849a', fontSize: 14 }}
          className="d-md-none d-lg-none align-items-center font-weight-bold mb-1 cursor-pointer"
        >
          FAQ
        </a>
      </div>
      <p className="cursor-pointer mb-1">
        <span
          className="d-flex align-items-center cursor-pointer"
          onClick={() => handleLogout()}
        >
          {/* <img src="/images/logout.svg" alt="logout" className=" mt-1" /> */}
          <span className="font-weight-bold">Logout</span>
        </span>
      </p>
    </React.Fragment>
  );

  return (
    <>
      <Header className={`app-header `} style={{ backgroundColor: '#327550' }}>
        <div className="nav">
          <Logo logoType={mode} />
        </div>
        {userRoles.includes('admin') || userRoles.includes('cad') ? (
          <div className="d-flex align-items-center">
            <a
              href="http://ipl2021samfin.azurewebsites.net/api/statusreport?code=9NT1akpAEFcT57oZwJj7tj9IXFTGhcqookJIPVXfmmM/pueabHayQQ=="
              target="_blank"
              style={{ color: 'white' }}
              rel="noreferrer"
            >
              Dashboard
            </a>
            {window.location.pathname !== '/app/dashboard' ? (
              <a
                href="/app/dashboard"
                className="pl-3"
                style={{ color: 'white' }}
              >
                New Dashboard
              </a>
            ) : window.location.pathname === '/app/dashboard' ? (
              <a
                href="/app/admin/home"
                className="pl-3"
                style={{ color: 'white' }}
              >
                Home
              </a>
            ) : (
              ''
            )}
            <p
              className="pl-3 mb-0 cursor-pointer"
              onClick={() => handleLSAMIS()}
              style={{ color: 'white' }}
            >
              LSA-MIS
            </p>
          </div>
        ) : (
          <React.Fragment>
            <div
              style={{
                color: 'white',
                paddingRight: '20px',
                fontSize: 16,
                fontWeight: 600
              }}
              className="d-md-flex align-items-center cursor-pointer media_fpo"
              onClick={() => handleGatewayHome()}
            >
              FPO Gateway
            </div>
            <a
              href="/app/faq"
              style={{ color: 'white', fontSize: 16, fontWeight: 600 }}
              className="d-md-flex align-items-center cursor-pointe media_faq"
            >
              FAQ
            </a>
          </React.Fragment>
        )}
        {!isFrontPage ? (
          <div className="nav-right">
            <div
              style={{ color: 'white' }}
              className="d-flex align-items-center"
            >
              <QuestionCircleFilled
                style={{ paddingRight: 10, color: '#f6bd44' }}
                height={80}
                width={80}
                onClick={() => setIsModalVisible(true)}
              />
              <span style={{ paddingRight: 10, color: '#f6bd44' }}>
                {mobileNo && mobileNo.mobile}
              </span>
              <span>
                <Popover
                  placement="bottom"
                  // overlayStyle={{ width: '10vw' }}
                  content={content}
                  trigger="click"
                >
                  <div className="cursor-pointer">
                    <UserOutlined className="profile_image" />
                  </div>
                </Popover>
              </span>
            </div>
          </div>
        ) : null}
      </Header>
      <Modal
        className={'custom-modal'}
        centered={true}
        closable={true}
        maskClosable={true}
        visible={isModalVisible}
        footer={null}
        closeIcon={<CloseCircleFilled />}
        onCancel={() => setIsModalVisible(false)}
      >
        <ol>
          <li>
            <Row gutter={24}>
              <Col xs={12} sm={8}>
                <span style={{ fontWeight: 500, color: '#455560' }}>
                  Toll Free Number
                </span>
              </Col>
              <Col xs={12} sm={1}>
                :
              </Col>
              <Col xs={12} sm={14}>
                <span style={{ fontWeight: 800 }}> 97908 97000</span>
              </Col>
            </Row>
          </li>
          <Divider />
          <li>
            <Row gutter={24}>
              <Col xs={12} sm={8}>
                <span style={{ fontWeight: 500, color: '#455560' }}>
                  Flow Chart :
                </span>
              </Col>
              <Col xs={12} sm={1}>
                :
              </Col>
              <Col xs={12} sm={14}>
                <a
                  href={'/SAM_IPL _flow.docx'}
                  target={'_blank'}
                  style={{ fontWeight: 800 }}
                  rel="noreferrer"
                >
                  Click here to download
                </a>
              </Col>
            </Row>
          </li>
        </ol>
      </Modal>

      <AddFeedback handleClose={() => handleCancel()} visible={visible} />
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { headerNavColor } = theme;
  return { headerNavColor };
};

export default connect(mapStateToProps, null)(HeaderNav);
