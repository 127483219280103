import React, { useState } from 'react';
import './feedback.scss';
import { Input, Button, Form, Modal } from 'antd';
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import Authorization from 'utils/Authorization';
export const AddFeedback = ({ visible, handleClose }) => {
  const { TextArea } = Input;
  const accessToken = Authorization.getAccessToken();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    let body = { ...values };
    setIsLoading(true);
    axios({
      method: 'post',
      url: `${API_BASE_URL}loanapplication/createFeedback`,
      headers: { Authorization: accessToken },
      data: body
    }).then(() => handleCancel());
  };

  const handleCancel = () => {
    form.setFieldsValue({ emailId: '', mobile: '', feedback: '' });
    handleClose();
  };

  return (
    <Modal visible={visible} onCancel={handleCancel} footer={null}>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <div className="rounded add-feedback">
          <div className="d-flex justify-content-between">
            <span className="custom-title">Leave feedback</span>
          </div>
          <p>Your valuable feedback would help us serve you better</p>
          <div className="bg-white pt-2">
            <div className="row">
              <div className="col-12">
                <Form.Item
                  label="Email ID"
                  name="emailId"
                  rules={[
                    { required: true, message: 'Please enter your email' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Contact Number"
                  name="mobile"
                  rules={[
                    { required: true, message: 'Please enter your number' }
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input minLength={10} maxLength={10} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="bg-white feedback-text-area">
            <div className="row">
              <div className="col-12">
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Anything that can be improved?"
                  name="feedback"
                  rules={[{ required: true, message: 'Please enter comments' }]}
                  className="w-100"
                >
                  <TextArea placeholder="Your feedback" />
                </Form.Item>
              </div>
            </div>
            <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
