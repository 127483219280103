const ProdEndPoint = 'https://agfinmartserver.azurewebsites.net/api/'; //prod
const endPoint = 'https://testagfincampaingapi.azurewebsites.net/api/'; //uat
const ProdUploadURl = 'https://sfdoccreate.azurewebsites.net/';
const devUploadURl = 'https://sfdoccreatedev.azurewebsites.net/';
const prodUserGWUrl = 'https://fpogateway.com';
const prodAdminGWUrl = 'https://admin.fpogateway.com';
const devUserGWUrl = 'https://testagfincampaignui.azurewebsites.net/admin/auth';
const devUserGWUrlTEST = 'http://localhost:3000/admin/auth'; // test
const devAdminGWUrl = 'https://dashboard.fpogateway.com';

const dev = {
  API_ENDPOINT_URL: endPoint,
  API_DOC_UPLOAD_ENDPOINT_URL: devUploadURl,
  USER_GWURL: devUserGWUrl,
  ADMIN_GWURL: devAdminGWUrl
};

const prod = {
  API_ENDPOINT_URL: ProdEndPoint,
  API_DOC_UPLOAD_ENDPOINT_URL: ProdUploadURl,
  USER_GWURL: prodUserGWUrl,
  ADMIN_GWURL: prodAdminGWUrl
};

const test = {
  API_ENDPOINT_URL: endPoint,
  API_DOC_UPLOAD_ENDPOINT_URL: devUploadURl,
  USER_GWURL: devUserGWUrlTEST,
  ADMIN_GWURL: devUserGWUrlTEST
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
